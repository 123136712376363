import * as React from "react"
import Seo from "../components/seo"
import "../styles/styles.sass"
import ResumeEmployment from "../views/resume/resumeEmployment"

const NewResumePage = () => {
  return (
  <main className="resumeView">
    <Seo
      title="Phil Amour — Resume"
      description="Phil is a passionate designer with more than 12 years of experience in UI/UX and mobile interface design. Like a Swiss Army knife, through various positions he has developed a set of proficiencies to successfully handle product design and product management in any capacity. He believes that transparency, good communication, and a great user experience is vital in making a successful product—a philosophy that is borne out in his work—designing gorgeous and easy-to-use products that delight millions of users."
      image="/imageSEOResume.jpg"
    />

    <section className="resumeSection headerSection">
      <a href="https://philamour.com" target="_blank" rel="noreferrer" className="headerName">Phil Amour</a>
      
      <div className="headerDetails">
        <h4 className="headerRole">Senior Product Designer</h4>
        <a href="mailto:hire@philamour.com"  className="headerContact">hire@philamour.com</a>
      </div>

      <p className="headerAbout">I am a passionate designer with more than 14 years of experience in UI/UX and mobile interface design. Like a Swiss Army knife, through various positions, I have developed a set of proficiencies to successfully handle product design and product management in any capacity. I believe that transparency, good communication, and a great user experience are vital in making a successful product—a philosophy that is borne out in my work—designing gorgeous and easy-to-use products that delight millions of users.</p>
    </section>

    <article className="resumeSection employmentSection">
      <h2 className="sectionTitle">Featured Work</h2>

{/*      <ResumeEmployment
        employerName="Lightricks"
        employerLink="https://lightricks.com"
        employmentRole="Lead Designer"
        employmentDuration="2018 – 2022"
        employmentDescription="Designing mobile products that transform creativity. Lightricks' award-winning apps (FaceTune, Enlight Photofox, Enlight Videoleap, Enlight Quickshot and Enlight Pixaloop) are used by tens of millions of mobile creators proving that mobile can be an amazing platform for creating content, not just for consuming it. Since joining Lightricks, I've helped launch PosterBoost (iOS) and Boosted (Web and iOS). Currently working on cross-organizational design system."
      />*/}

      <ResumeEmployment
        employerName="Blink"
        employerLink="https://www.joinblink.com/"
        employmentRole="Senior Product Designer"
        employmentDuration="2022 – 2025"
        employmentDescription="Designed bespoke features for major clients such as McDonald's, JD Sports, NHS, Domino's, and Nokia. Led end-to-end design of Communities, Stories, onboarding journeys, moderation tools, and feed redesign across iOS, Web, and Android. Conducted user research and testing to enhance usability, driving adoption and engagement across diverse enterprises."
      />

      <ResumeEmployment
        employerName="Lightricks"
        employerLink="https://lightricks.com"
        employmentRole="Lead Designer"
        employmentDuration="2018 – 2022"
        employmentDescription={<>
          Led the design of PosterBoost (iOS) - conducting market research and establishing product positioning, leading ux research, designing and prototyping in close collaboration with many senior developers, managing & directing freelancers and studios on creative content, and growing the design team.
          <br/><br/>
          During PosterBoost's merger with VideoBoost - designed and prototyped many features for Boosted Mobile (iOS & Android). Established and launched the design of Boosted for Web. Mentored junior designers and established a cross-platform Boosted Design System.
          <br/><br/>
          Led design efforts on the web payments team, from research to design of payment pages and landing microsites, including Facetune 2's landing page, and helped increase conversion and revenue.
          <br/><br/>
          Created and managed an organization-wide design system spanning multiple products and platforms.
          <br/><br/>
          Worked closely with the Facetune team to redesign the Facetune app used by 200 million+ people.
        </>}
      />

      <ResumeEmployment
        employerName="CueHit"
        employerLink="https://www.powerdms.com/power-engage"
        employmentRole="Cofounder & Head of Design"
        employmentDuration="2019 – 2021"
        employmentDescription="Designed and built the front-end of a SaaS targeted at 911 call centers, law enforcement, EMS, and fire agencies that reshapes citizen connections for public safety. Partnered with Union County PD and 9-1-1 in New Jersey to introduce a new era of community engagement for public safety. Thanks to our innovative solution, the NJ department is seeing a 50% response rate for post-incident surveys vs less than five percent before. Designed and built the front-end of many innovative features - surveys, automated rules, communication tools (text/email/video calls/chatbots), analytics, internal mobile app, etc. After partnering with 50+ paying customers ranging from police departments to 911 call centers and incurring 1M+ in ARR, CueHit has been acquired by NEOGOV."
      />

      <ResumeEmployment
        employerName="Petline"
        employerLink="https://petline.netlify.app"
        employmentRole="Cofounder & Head of Design"
        employmentDuration="2019"
        employmentDescription="Cofounded a bootstrapped B2B SAAS business that empowers vets and techs to create better relationships with pet owners. Defined product based on market research and interviews with leading US vets. Led product and creative direction, took ownership of front-end implementation and went to market during COVID-19 pandemic to help with vet clinics with telemedicine transition."
      />

      <ResumeEmployment
        employerName="BridgeRock Capital"
        employerLink="https://www.bridgerockcap.com"
        employmentRole="Senior Product Designer"
        employmentDuration="2019 - 2020"
        employmentDescription="Defined, designed, and helped implement an innovative cross-platform expense-tracking tool."
      />

      <ResumeEmployment
        employerName="TargetSolutions"
        employerLink="https://www.targetsolutions.com/check-it/"
        employmentRole="Lead Product Designer"
        employmentDuration="2019 - 2020"
        employmentDescription="After Halligan's acquisition by Vector Solutions, Halligan rebranded as Check It and became a part of TargetSolutions' public safety technology suite. I remain a Lead Product Designer on the Check It team, continuing to deliver the best experience possible to Fire and EMS agencies."
      />

      <ResumeEmployment
        employerName="Halligan"
        employerLink="https://www.vectorsolutions.com/solutions/vector-check-it/"
        employmentRole="Head of Design"
        employmentDuration="2017 – 2019"
        employmentDescription="Worked closely with the founders, engineers and core customers to deliver the greatest value to the consumer at the lowest cost to the business. Helped identify and define highly competitive feature set in order to deliver an incredibly attractive solution to many businesses despite the existence of other mature and resourceful competitors. Responsibilities include maintaining a design system and specification of the product for different levels of account permissions and whitelabeled variations - all across the Web, iOS and Android. Halligan was acquired by Vector Solutions in January 2019."
      />

      <ResumeEmployment
        employerName="Snupps"
        employerLink="https://web.archive.org/web/20210617081907/http://snupps.com/"
        employmentRole="Lead Product Designer"
        employmentDuration="2013 – 2018"
        employmentDescription={<span>Responsibilities include qualitative and quantitative analysis-led User Experience, User Interface, Animation, and Production Design of multiple digital products across iOS, Android and the Web whilst ensuring efficient delivery and quality of each release and working closely with the development team and stakeholders in an agile environment. Led research and design of 'List on eBay' integration which resulted in a <a href="http://pages.ebay.com/snupps/" className="link">partnership between Snupps and eBay</a> and raised eBay's C2C KPIs beyond expectations. Snupps app has been featured by both Apple and Google on App Store and Google Play.</span>}
      />

      <ResumeEmployment
        employerName="Shopcade"
        employerLink="https://shopcade.com"
        employmentRole="Lead Mobile Product Designer"
        employmentDuration="2013"
        employmentDescription="Designed both the iOS and Android version of Shopcade from the ground up and redefined the overall user experience."
      />

      <ResumeEmployment
        employerName="Chess.com"
        employerLink="https://chess.com"
        employmentRole="Lead Mobile Product Designer"
        employmentDuration="2013"
        employmentDescription="Worked closely with the co-founder to redefine the iOS app interface. The iOS app is currently the highest rated Chess app with around 2,300 5-star reviews and over 10 million users worldwide."
      />

      <ResumeEmployment
        employerName="Best Games"
        employerLink="https://appstore.com"
        employmentRole="Lead Product Designer"
        employmentDuration="2012 – 2013"
        employmentDescription="Led ideation, definition, design, and launch of multiple products with Erik Allebest (Chess.com founder), such as WhoCan, uprise and Zombie Tag."
      />

      <ResumeEmployment
        employerName="Tasty Inc."
        employerLink="https://tasty.io"
        employmentRole="Lead Product Designer"
        employmentDuration="2011 – 2012"
        employmentDescription="Worked on various social and discovery products for restaurants. From ideas and visions, through wireframes, mockups, and finally working prototypes, these product ideas were converted into working apps by a group of talented engineers."
      />

      <ResumeEmployment
        employerName="Freelance"
        employerLink="https://philamour.com"
        employmentRole="Senior Product Designer"
        employmentDuration="2008 – PRESENT"
        employmentDescription="Definition, development and refinement of digital products for many US-based clients (both individual entrepreneurs as well as businesses.) Showcase available on request."
      />
    </article>

  </main>
  )
}

export default NewResumePage
